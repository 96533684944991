import React,{useContext} from "react";
import { Context } from "../../context/Context";
import { Fade } from "react-awesome-reveal";

export default function HeroTwo() {
  const {home, isHomeLoading} = useContext(Context)
  const {title, description, image} = home
  return (
    <>
      <section
      id='home'
        className="hero-section pt-100 background-img"
        style={{
          minHeight:'70vh',
          paddingTop:'100px',
          paddingBottom:'100px',
          background:
            "url('assets/img/hero-bg-1.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left text-white mt-5">
              <Fade bottom delay={500}>
                <h1 id='headtitle' style={{color:'#313e5b'}}>
                {title}
                </h1>
                </Fade>
                <Fade bottom delay={1000}>
                <div className="lead"  style={{color:'#313e5b'}} dangerouslySetInnerHTML={{__html:description}}/>
                </Fade>
    <br/>
    <Fade bottom delay={1400}>
                <a style={{cursor:'pointer'}} className="btn app-store-btn" onClick={() => window.location.replace("/#solutions")}>
                  Read More
                </a>
                </Fade>
                
              
              </div>
            </div>
         
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
                <img
                  src={image}
                  alt="img"
                  width="700"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
      
        </div>
      </section>
    </>
  );
}

/* <a href="#download" className="btn app-store-btn2">
Contact Us
</a>
*/