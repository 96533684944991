import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { 
  DropdownMenu, DropdownItem, 
  ButtonDropdown, DropdownToggle,NavItem 
} from "reactstrap"

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [dropdownOpen, setOpen] = React.useState(false); 

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
   
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav #253a4c-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            
            <Link to="/" className="navbar-brand">
              {scroll > headerTop ?  (
                <img
                  src="assets/img/sustenergo_white_hor.png"
                  width="170"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/sustenergo_blue_hor.png"
                  width="170"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            {scroll > headerTop ? (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"  style={{color:'whitesmoke'}}></span>
            </button> ) : (
               <button
               className="navbar-toggler"
               type="button"
               
               data-toggle="collapse"
               data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent"
               aria-expanded="false"
               aria-label="Toggle navigation"
             >
               <span className="ti-menu"></span>
             </button>

              )}
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                <HashLink className="nav-link" smooth to='/#home'>
                {scroll > headerTop ? (
                <a
                  style={{color:'white'}}
                >Home</a>
              ) : (
                <a
                  style={{color:'#253a4c', opacity:'1'}}
                >Home</a>
              )}
                  </HashLink>
                  </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='/about#whoweare'>
                  {scroll > headerTop ? (
                <a
                  style={{color:'white'}}
                >Αbout</a>
              ) : (
                <a
                  style={{color:'#253a4c', opacity:'1'}}
                >About</a>
              )}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link">
                  {scroll > headerTop ? (
               <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }} 
               isOpen={dropdownOpen}> 
               <DropdownToggle id='dropdown' caret> 
                  <a>Solutions</a>
               </DropdownToggle> 
               <DropdownMenu> 
                   <DropdownItem tag={Link} className='drop2' to="/verify">VERIFY</DropdownItem> 
                   <DropdownItem tag={Link} className='drop2' to="/intema">INTEMA</DropdownItem> 
                   <DropdownItem tag={Link} className='drop2' to="/use">USE</DropdownItem> 
                   <DropdownItem tag={Link} className='drop2' to="/smartgo">SmartGO</DropdownItem> 
               </DropdownMenu> 
           </ButtonDropdown> 
                
              ) : (
                <ButtonDropdown toggle={() => { setOpen(!dropdownOpen) }} 
                isOpen={dropdownOpen}> 
                <DropdownToggle id='dropdown'style={{color:'#253a4c'}} caret> 
                <a style={{color:'#253a4c', opacity:'1'}}>Solutions</a>
                </DropdownToggle> 
                <DropdownMenu> 
                    <DropdownItem tag={Link} className='drop2' to="/verify">VERIFY</DropdownItem> 
                    <DropdownItem tag={Link} className='drop2' to="/intema">INTEMA</DropdownItem> 
                    <DropdownItem tag={Link} className='drop2' to="/use">USE</DropdownItem> 
                    <DropdownItem tag={Link} className='drop2' to="/smartgo">SmartGO</DropdownItem> 
                </DropdownMenu> 
            </ButtonDropdown> 
              )}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/Projects">
                  {scroll > headerTop ? (
                <a
                  style={{color:'white'}}
                >Projects</a>
              ) : (
                <a
                  style={{color:'#253a4c', opacity:'1'}}
                >Projects</a>
              )}
                  </HashLink>
                </li>
        
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/#contact">
                  {scroll > headerTop ? (
                <a
                  style={{color:'white'}}
                >Contact</a>
              ) : (
                <a
                  style={{color:'#253a4c', opacity:'1'}}
                >Contact</a>
              )}
                  </HashLink>
                </li>
             
              
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;

/*

                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='/projects'>
                  {scroll > headerTop ? (
                <a
                  style={{color:'white'}}
                >Our Projects</a>
              ) : (
                <a
                  style={{color:'#253a4c', opacity:'1'}}
                >Our Projects</a>
              )}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/#news">
                  {scroll > headerTop ? (
                <a
                  style={{color:'white'}}
                >News</a>
              ) : (
                <a
                  style={{color:'#253a4c', opacity:'1'}}
                >News</a>
              )}
                  </HashLink>
                </li>

 */