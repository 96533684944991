import React,{useContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../context/Context'
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/header/PageHeader";

import FeatureImg from "../components/Verify/Whychoose";

import Features from "../components/Verify/Features";

import Intro from "../components/Verify/Intro";
import Whoisitfor from "../components/Verify/Whoisitfor";

export default function BlogDetails() {
  const {verify, isVerifyLoading} = useContext(Context)
  const {logo, tagline} = verify
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        image={logo}
        PageTitle={tagline}
        Parent="Blog"
      />
      <Intro/>
      <FeatureImg/>
      <Whoisitfor/>
      <Features/>
      <Footer />
    </Layout>
  );
}
