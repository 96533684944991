import React,{useContext,useEffect} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../context/Context'
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/header/PageHeader";
import Intro4 from "../components/Intema/Intro4";
import Whychoose4 from "../components/Intema/Whychoose4";
import Whoisitfor4 from "../components/Intema/Whoisitfor4";
import Features4 from "../components/Intema/Features4";


export default function Intema() {
  const {intema, isIntemaLoading} = useContext(Context)
  const {logo, tagline} = intema

  useEffect(() => {
    // Update the document title using the browser API
   console.log(5)
  });
  
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        image={logo}
        PageTitle={tagline}
        Parent="Blog"
      />
      <Intro4/>
      <Whychoose4/>
      <Whoisitfor4/>
      <Features4/>
      <Footer />
    </Layout>
  );
}
