import Footer from "../../components/layout/footer/Footer";

import Contact from "../../components/contact/Contact";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";

import HeroTwo from "./Hero";

import BrandCarousel from "../../components/carousels/BrandCarousel";

import ProjectList from "../../components/features/projectlist";

export default function HomeTwo() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroTwo />
      <ProjectList/>
      <Contact bgColor="gray-light-bg" />
      <BrandCarousel/>
      <Footer space />
    </Layout>
  );
}
/*
 <AboutApp />
      <AboutApp2/>
      <FeatureImg ImgSource="assets/img/image-10.png" />
      <FeatureImgThree />

      <LatestNewsOne light bgColor="gray-light-bg" />
      <TeamTwo />
      <Contact bgColor="gray-light-bg" />
      <BrandCarousel />
*/