import React,{useContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'

const Promo = () => {
  const {about, isAboutLoading} = useContext(Context)
  const {aboutTitle, aboutDescription, aboutSectionTitle} = about
  return (
    <>
      <section className="promo-section ptb-100" id='whoweare'>
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-md-10">
              <div className="section-heading text-center mb-5">
                <h2>{aboutTitle} </h2>
                
                <p style={{textAlign:'justify'}}>
                <div dangerouslySetInnerHTML={{__html:aboutDescription}}/>
               </p>
              
              </div>
            </div>
          </div>
         
        
        </div>
      </section>
    </>
  );
};

export default Promo;
