
import Contact from "../components/contact/Contact";

import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";

import Promo from "../components/promo/Promo";
import Whyus from "../components/promo/Whyus";

import TeamCarousel from "../components/carousels/TeamCarousel";
import HeroTwo from "../themes/index/Hero";


export default function About() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroTwo/>
      <Promo />
      <Whyus/>
      <TeamCarousel/>
      <Contact bgColor />
      <Footer space />
    </Layout>
  );
}
