import React,{useContext} from "react";
import { Context } from "../../context/Context";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from 'react-router-dom'



export default function ProjectList() {
  const navigate = useNavigate();
  const routeChange1 = () =>{ 
    navigate('/verify');
  }
  const routeChange2 = () =>{ 
    navigate('/intema');
  }
  const routeChange3 = () =>{ 
    navigate('/use');
  }
  const routeChange4 = () =>{ 
    navigate('/smartgo');
  }
  const {solutions, isSolutionsLoading} = useContext(Context)
  const {smalltitle,bigtitle,solution1text1, solution1text2,solution2text1, solution2text2, solution3text1, solution3text2, solution4text1, solution4text2, logo1, logo2, logo3, logo4} = solutions
  return (
    <>
      <section id="solutions" className="feature-section ptb-100">
        <div className="container">
        <div className="row justify-content-center">
          
        <div >
           
              <div className="feature-contents section-heading">
              

                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                 
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab7-1"
                        data-toggle="tab"
                        style={{fontSize:'17px',fontWeight:'500'}}
                      >
                  <img src={logo1} style={{height:'50px'}}></img>
                        <br/>
                        
                      </a>
                    </li>
                   
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab7-2"
                        data-toggle="tab"
                        style={{fontSize:'17px',fontWeight:'500'}}
                      >
                           <img src={logo2} style={{height:'50px'}}></img>
                        <br/>
                      
                      </a>
                    </li>
                
                    <li className="nav-item">
                      <a
                      style={{fontSize:'17px',fontWeight:'500'}}
                        className="nav-link h6"
                        href="#tab7-3"
                        data-toggle="tab"
                      >
                           <img src={logo3} style={{height:'50px'}}></img>
                        <br/>
                 
                      </a>
                    </li>
              
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab7-4"
                        data-toggle="tab"
                        style={{fontSize:'17px',fontWeight:'500'}}
                      >
                           <img src={logo4}style={{height:'50px'}}></img>
                        <br/>
                      
                      </a>
                    </li>
                    
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab7-1">
                    <Fade delay={200}>
                    <div className="row">
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution1text1}}/>
                       
                        </div>
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution1text2}}/>
                        <br/>
                        <button  onClick={() => routeChange1()} className="btn app-store-btn" style={{padding:'8px',borderRadius:'12px'}}>Read More</button>
                        </div>
                      
                      </div>
                      </Fade>
                    </div>
                    <div className="tab-pane" id="tab7-2">
                    <Fade delay={200}>
                    <div className="row">
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution2text1}}/>
                       
                        </div>
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution2text2}}/>
                        <br/>
                        <button  className="btn app-store-btn" onClick={() =>routeChange2()} style={{padding:'8px',borderRadius:'12px'}}>Read More</button>
                        </div>
                      </div>
                      </Fade>
                    </div>
                    <div className="tab-pane" id="tab7-3">
                    <Fade delay={200}>
                    <div className="row">
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution3text1}}/>
                       
                        </div>
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution3text2}}/>
                        <br/>
                        <button  className="btn app-store-btn" onClick={() => routeChange3()} style={{padding:'8px',borderRadius:'12px'}}>Read More</button>
                        </div>
                      </div>
                      </Fade>
                    </div>
                    
                    <div className="tab-pane" id="tab7-4">
                    <div className="row">
                    <Fade delay={200}>
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution4text1}}/>
                       
                        </div>
                        <div className="col single-feature mb-4">
                        <div dangerouslySetInnerHTML={{__html:solution4text2}}/>
                        <br/>
                        <button  className="btn app-store-btn" onClick={() => routeChange4()} style={{padding:'8px',borderRadius:'12px'}}>Read More</button>
                        </div>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
