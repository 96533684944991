import React, {useState, useEffect, useCallback, useContext} from 'react'
import { client } from './client'
import { CleanUpAbout, CleanUpHome, CleanUpContact, CleanUpFooter, CleanUpSolutions, CleanUpVerify, CleanUpSmartgo, CleanUpUse, CleanUpIntema} from './helpers'



export const Context = React.createContext()

export const Provider = (props) =>{
    const[intema, setIntema] = useState({})
    const[isIntemaLoading,setIsIntemaLoading] = useState(false)

    const[use, setUse] = useState({})
    const[isUseLoading,setIsUseLoading] = useState(false)
    const[Smartgo, setSmartgo] = useState({})
    const[isSmartgoLoading,setIsSmartgoLoading] = useState(false)
    const[verify, setVerify] = useState({})
    const[isVerifyLoading,setIsVerifyLoading] = useState(false)
    const[about, setAbout] = useState({})
    const[isAboutLoading,setIsAboutLoading] = useState(false)
    const[home, setHome] = useState({})
    const[isSolutionsLoading, setIsSolutionsLoading] = useState(false)
    const[solutions, setSolutions] = useState({})
    const[isHomeLoading, setIsHomeLoading] = useState(false)
    const[contact, setContact] = useState({})
    const[isContactLoading, setIsContactLoading] = useState(false)
    const[footer, setFooter] = useState({})
    const[isFooterLoading, setIsFooterLoading] = useState(false)

    const saveIntemaData = useCallback((rawdata) =>{
        const cleanIntemaData = CleanUpIntema(rawdata)
        setIntema(cleanIntemaData)
    }, [])

    const saveAboutData = useCallback((rawdata) =>{
        const cleanAboutData = CleanUpAbout(rawdata)
        setAbout(cleanAboutData)
    }, [])

    const saveVerifyData = useCallback((rawdata) =>{
        const cleanVerifyData = CleanUpVerify(rawdata)
        setVerify(cleanVerifyData)
    }, [])

    const saveSmartgoData = useCallback((rawdata) =>{
        const cleanSmartgoData = CleanUpSmartgo(rawdata)
        setSmartgo(cleanSmartgoData)
    }, [])

    const saveUseData = useCallback((rawdata) =>{
        const cleanUseData = CleanUpUse(rawdata)
        setUse(cleanUseData)
    }, [])



    const saveHomeData = useCallback((rawdata) =>{
        const cleanHomeData = CleanUpHome(rawdata)
        setHome(cleanHomeData)
    }, [])

    const saveSolutionsData = useCallback((rawdata) =>{
        const cleanSolutionsData = CleanUpSolutions(rawdata)
        setSolutions(cleanSolutionsData)
    }, [])

    const saveContactData = useCallback((rawdata) =>{
        const cleanContactData = CleanUpContact(rawdata)
        setContact(cleanContactData)
    }, [])

    const saveFooterData = useCallback((rawdata) =>{
        const cleanFooterData = CleanUpFooter(rawdata)
        setFooter(cleanFooterData)
    }, [])

    const getIntema = useCallback(async () => {
        setIsIntemaLoading(true)
        try {
            const response = await client.getEntry('5uh8249MiGb0vojVOINng0')
            if (response){
                saveIntemaData(response)
            }else{
                setIntema({})
            }
            setIsIntemaLoading(false)
        } catch (error) {
            console.log(error)
            setIsIntemaLoading(false)
        
        }
        }, [saveIntemaData])
        
        useEffect(()=> {
            getIntema()
        }, [getIntema])
    
   
const getAbout = useCallback(async () => {
setIsAboutLoading(true)
try {
    const response = await client.getEntry('6FEf39rcFjIPWbaeCYLTMf')
    if (response){
        saveAboutData(response)
    }else{
        setAbout({})
    }
    setIsAboutLoading(false)
} catch (error) {
    console.log(error)
    setIsAboutLoading(false)

}
}, [saveAboutData])

useEffect(()=> {
    getAbout()
}, [getAbout])


const getVerify = useCallback(async () => {
    setIsVerifyLoading(true)
    try {
        const response = await client.getEntry('1FMPKVhAhC1w5MdQuEJMyB')
        if (response){
            saveVerifyData(response)
        }else{
            setVerify({})
        }
        setIsVerifyLoading(false)
    } catch (error) {
        console.log(error)
        setIsVerifyLoading(false)
    
    }
    }, [saveVerifyData])
    
    useEffect(()=> {
        getVerify()
    }, [getVerify])

    
const getSmartgo = useCallback(async () => {
    setIsSmartgoLoading(true)
    try {
        const response = await client.getEntry('4r6vuS7A1tTYbQvSRG8Yx1')
        if (response){
            saveSmartgoData(response)
        }else{
            setSmartgo({})
        }
        setIsSmartgoLoading(false)
    } catch (error) {
        console.log(error)
        setIsSmartgoLoading(false)
    
    }
    }, [saveSmartgoData])
    
    useEffect(()=> {
        getSmartgo()
    }, [getSmartgo])

    const getUse = useCallback(async () => {
        setIsUseLoading(true)
        try {
            const response = await client.getEntry('1V9aclA0DY5DlY2mshBQKq')
            if (response){
                saveUseData(response)
            }else{
                setUse({})
            }
            setIsUseLoading(false)
        } catch (error) {
            console.log(error)
            setIsUseLoading(false)
        
        }
        }, [saveUseData])
        
        useEffect(()=> {
            getUse()
        }, [getUse])
    
    

const getHome = useCallback(async () => {
    setIsHomeLoading(true)
    try {
        const response = await client.getEntry('640OjK5qZROtM1ZYpQaAsv')
        if (response){
            saveHomeData(response)
        }else{
            setHome({})
        }
        setIsHomeLoading(false)
    } catch (error) {
        console.log(error)
        setIsHomeLoading(false)
    
    }
    }, [saveHomeData])
    
    useEffect(()=> {
        getHome()
    }, [getHome])

    const getSolutions = useCallback(async () => {
        setIsSolutionsLoading(true)
        try {
            const response = await client.getEntry('5ejyHnQMMuND4UxGFq1VHF')
            if (response){
                saveSolutionsData(response)
            }else{
                setSolutions({})
            }
            setIsSolutionsLoading(false)
        } catch (error) {
            console.log(error)
            setIsSolutionsLoading(false)
        
        }
        }, [saveSolutionsData])
        
        useEffect(()=> {
            getSolutions()
        }, [getSolutions])
        
    

    const getContact = useCallback(async () => {
        setIsContactLoading(true)
        try {
            const response = await client.getEntry('4hChqhsfDMCaqGRN1La1DL')
            if (response){
                saveContactData(response)
            }else{
                setContact({})
            }
            setIsContactLoading(false)
        } catch (error) {
            console.log(error)
            setIsContactLoading(false)
        
        }
        }, [saveContactData])
        
        useEffect(()=> {
            getContact()
        }, [getContact])

        const getFooter = useCallback(async () => {
            setIsFooterLoading(true)
            try {
                const response = await client.getEntry('6UGUK1w8onO09whju6MDkB')
                if (response){
                    saveFooterData(response)
                }else{
                    setFooter({})
                }
                setIsFooterLoading(false)
            } catch (error) {
                console.log(error)
                setIsFooterLoading(false)
            
            }
            }, [saveFooterData])
            
            useEffect(()=> {
                getFooter()
            }, [getFooter])


    const contextData = {
        intema,
        isIntemaLoading,
        use,
        isUseLoading,
        Smartgo,
        isSmartgoLoading,
        about,
        verify,
        isVerifyLoading,
        isAboutLoading,
        home,
        solutions,
        isSolutionsLoading,
        isHomeLoading,
        contact,
        isContactLoading,
        footer,
        isFooterLoading
   
  
    }
    return(
    <Context.Provider value={contextData}>
        {props.children}
    </Context.Provider>
    )
}