import React,{useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { client } from "../../context/client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'

export default function Whoisitfor() {
  const {Smartgo, isSmartgoLoading} = useContext(Context)
  const {title3, description3, image3} = Smartgo


  return (
    <>
      <section id="about" className="about-us ptb-100 ">
        <div className="container">
          <div className="row align-items-center justify-content-between">
          <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={image3}
                  style={{borderRadius:'20px'}}
                  width="700"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7">
              
              <h2>{title3} </h2>
          
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                
                    <div>
                      <p> <div dangerouslySetInnerHTML={{__html:description3}}/></p>
                    </div>
                  </div>
                </li>
             
              </ul>
              <br/>
          
            </div>
            
          </div>
        </div>
        
      
      </section>
    </>
  );
}
