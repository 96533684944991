import React from "react";
import AppRoutes from "./routes/Routes";
import { Provider } from "./context/Context";

const App = () => {
  return (
    <Provider>
      <AppRoutes />
    </Provider>
  );
};

export default App;
