import React,{useContext,useEffect,useState,createContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../context/Context'
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import { Fade } from "react-awesome-reveal";
import {Grid,Card,CardContent,Typography} from "@mui/material"
import Container from "@mui/material/Container"
import CardActions from '@mui/material/CardActions';
import { client } from "../context/client";
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Markdown from 'react-markdown'


const style = {

  position: "fixed",
  
  justifyContent: "center",
  top: '50%',
  maxWidth:'800px',
  backdrop: false,
  overlay:'red',
  left: '50%',
  maxHeight:'500px',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY:'auto'
};

const FilterContext = createContext();

export default function Projects() {
  
  const [projects,setProjects]= useState([])
  const [filters, setFilters] = useState({
    type:'',
    tool:'',
    programme:''
});
const [open, setOpen] = React.useState(false);
  const [description,setDescription] = useState()
  const [fulltitle,setFulltitle] = useState()
  const [title,setTitle] = useState()
  const [image,setImage] = useState()
  const handleOpen = (description,title,image,title2) => {
    setDescription(marked(description));
    setTitle(title);
    setFulltitle(title2)
    setImage(image);
    setOpen(true)};
  const handleClose = () => setOpen(false);

useEffect(() => {
  const getAllEntries = async () => {
    try {
      await client.getEntries({content_type:'projects'}).then((entries) => {
        console.log(entries,'hhhhh')
        setProjects(entries.items)
      })
    } catch (error) {
      console.log(`Error fetching authors ${error}`);
    }
  };
  getAllEntries()
}, [])
const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
    }));
};
const filteredProjects = projects.filter((project) => {
  console.log(project.metadata.tags[0].sys.id,'wddw',filters.tool)
  return (
    (filters.tool === '' || project.metadata.tags[0].sys.id === filters.tool || project.metadata.tags[1].sys.id === filters.tool || project.metadata.tags[2].sys.id === filters.tool) &&
      (filters.type === '' ||
      project.metadata.tags[0].sys.id === filters.type ||  project.metadata.tags[1].sys.id === filters.type ||  project.metadata.tags[2].sys.id === filters.type) &&
      (filters.programme === '' ||
        project.metadata.tags[0].sys.id === filters.programme ||  project.metadata.tags[1].sys.id === filters.programme ||  project.metadata.tags[2].sys.id === filters.programme)
      
  );
});
  useEffect(() => {
    // Update the document title using the browser API
   console.log(5)
  });
  
  return (
    <Layout>
      <Navbar darkBg />
      <section
      id='home'
        style={{
          minHeight:'70vh',
          paddingTop:'150px',
          paddingBottom:'100px',
         
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12 col-lg-12">
              <Fade bottom delay={500}>
                <h2 id='headtitle' style={{color:'#313e5b'}}>
                Projects
                </h2>

            <FilterContext.Provider value={{
            filters, handleFilterChange
        }}>
            <div>
               <FilterControls/>
               <br/> 
               <Grid container spacing={5}>
               
                    {filteredProjects.map((project) => (
                        <Grid item xs={12} sm={3} ms={3} key={project.sys.id}>
                       
                            <Card sx={{ maxWidth: 345 }} style={{backgroundColor: "#eff6fd"}}>
      <CardMedia
        sx={{ height: 140 }}
        style={{backgroundSize:'contain',margin:'14px'}}
        image={project.fields.projectImage.fields.file.url}
        title="green iguana"
      />
      <CardContent style={{borderTop:'2px solid #166aa3'}}>
        <Typography gutterBottom variant="h5" component="div">
          {project.fields.Title}
        </Typography>
        
        <Typography variant="body2" color="text.secondary">
          {project.fields.fullTitle.length>=90? <Markdown>{project.fields.fullTitle.slice(0, 90) + "..."}</Markdown>: <Markdown>{project.fields.fullTitle}</Markdown>}
        </Typography>  
        <div style={{marginTop:'6px'}}>
        <a href="/tags/javascript/"><span class="tag tag-javascript tag-lg">{project.metadata.tags[0].sys.id}</span></a>
        <a href="/tags/javascript/"><span class="tag tag-javascript tag-lg">{project.metadata.tags[1].sys.id}</span></a>
        <a href="/tags/javascript/"><span class="tag tag-javascript tag-lg">{project.metadata.tags[2].sys.id}</span></a>
        <br/>
        <Button size="small" style={{float:'right',fontSize:'12px',outline:'none'}} onClick={() => handleOpen(project.fields.description,project.fields.Title,project.fields.projectImage.fields.file.url,project.fields.fullTitle)} >Learn More</Button></div> 
     <br/> </CardContent>
   
    </Card>
                            </Grid>
                            
                    ))}
               </Grid>
               <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       
      >
        <Box sx={style}>
        
        <div id='nametitle' style={{textAlign:'center'}}>
        <h4 className="name" style={{margin: '1rem 0 0rem', display:'inline-block'}}>{title}
       <hr/></h4>
       <br/>
        <div id='left' style={{color:'#166aa3',fontSize:'18px',margin: '0em',display:'inline-block'}}>
        <Markdown >{fulltitle}</Markdown>  </div>
        </div>
          <Typography id="modal-modal-title" style={{textAlign:'justify'}}>
          <img src={image} style={{float:'right',width:'260px',padding:'4px', marginRight:'20px',top:'40px', position:'relative'}}/>
          <div id='left' dangerouslySetInnerHTML={{__html:description}}/>
          </Typography>
         
        </Box>
      </Modal>
            </div>
        </FilterContext.Provider>
           </Fade>
        
            </div>
          </div>
      
        </div>
      </section>
      
      <Footer />
    </Layout>
  );
}
const FilterControls = () => {
  const { filters, handleFilterChange } = useContext(FilterContext);
  return (
      <div style={{ marginBottom: '10px' }}>
        <select name="programme"
              value={filters.programme}
              onChange={handleFilterChange}
              style={{ marginRight: '10px' }}>
              <option value="">All Programmes</option>
              <option value="HEU">HEU</option>
              <option value="H2020">H2020 </option>
              <option value="National">National  </option>
              <option value="Other">Other </option>
          </select>
          <select name="type"
              value={filters.type}
              onChange={handleFilterChange}
              style={{ marginRight: '10px' }}>
              <option value="">All Types</option>
              <option value="RIA">RIA</option>
              <option value="IA">IA</option>
          </select>
          <select name="tool" value={filters.tool}
              onChange={handleFilterChange}
              style={{ marginRight: '10px' }}>
              <option value="">All Tools</option>
              <option value="VERIFY">VERIFY</option>
              <option value="INTEMA">INTEMA</option>
              <option value="SmartGO">SmartGO</option>
              <option value="Use">Use</option>
              <option value="Custom">Custom</option>
          </select>
    
      </div>
  );
};