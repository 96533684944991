import React,{useContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'

export default function Intro4() {
  const {intema, isIntemaLoading} = useContext(Context)
  const {title1, description1} = intema
return (
  <>
  
    <div id="features" className="feature-section ptb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <div className="section-heading text-center mb-5">
              <h2>{title1}</h2>
              <p style={{textAlign:'justify'}}>
              <div dangerouslySetInnerHTML={{__html:description1}}/>
            </p>
            </div>
          </div>
        </div>
 

      </div>
    </div>
  </>
);
}

