
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";

import logo from '../components/images/manager.png'
import logo2 from '../components/images/manager2.png'


export default function About() {
  return (
    <Layout>
      <Navbar darkBg />
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
      <h2> SUSTENERGO CERTH spin-off Private Company</h2>
      <h2 style={{fontSize:'1.4em'}}>Informatics Applications, Research & Development</h2>
 <br/>
<h6>Tax Identification number: <span id='details'>802321106 – Kozani Tax Authority</span></h6>
<h6>Business Registry Number:<span id='details'>174276636000</span></h6>
<h6>Registered Address: <span id='details'>Perdika 16, Kozani, 50100, Greece</span></h6>

<br/><br/>


<h5><img src={logo2} style={{width:'20px',marginRight:'6px'}}/><b >Managing Director </b></h5>
<h5 id='details'>Paraskevi Giourka</h5>
<h6>Address: <span id='details'>Agios Panteleimon Aminteou, 53200</span></h6>

<br/><br/>
<h4>Equity Breakdown</h4>

<h6>Total Equity: <span id='details'>€20.000</span></h6>
<br/>
<h6>Shareholders</h6>

<ol>

<li> <img src={logo} style={{width:'20px',marginRight:'6px'}}/>Nikolaos Nikolopoulos - 17%</li>

<li><img src={logo2} style={{width:'20px',marginRight:'6px'}}/>Paraskevi Giourka - 16%</li>

<li><img src={logo} style={{width:'20px',marginRight:'6px'}}/>Dimitrios Rakopoulos - 16%</li>

<li><img src={logo} style={{width:'20px',marginRight:'6px'}}/>Angelos-Saverios Skempris - 16%</li>

<li><img src={logo} style={{width:'20px',marginRight:'6px'}}/>Stefanos Petridis - 10%</li>

<li><img src={logo} style={{width:'20px',marginRight:'6px'}}/>Petros Iliadis - 10%</li>

<li><img src={logo} style={{width:'20px',marginRight:'6px'}}/>Andreas Seitaridis - 10%</li>

<li><img src={logo} style={{width:'20px',marginRight:'6px'}}/>Panagiotis Grammelis - 10%</li>

</ol>
</div></section>
      <Footer space />
    </Layout>
  );
}
