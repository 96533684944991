import React,{useContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'

export default function Whychoose3() {
  const {use, isUseLoading} = useContext(Context)
  const {title2, description2, image2} = use
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="about-content-left section-heading">
               
                <h2>{title2}</h2>
                <p>
                <div dangerouslySetInnerHTML={{__html:description2}}/>
               </p>
              
           

         
               
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img style={{padding:'80px'}}
                  src={image2}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
