import React,{useContext, useEffect, useState} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'
import { client } from "../../context/client";
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import logo from '../images/sustenergo_blue_hor_v2.png'

import "slick-carousel/slick/slick-theme.css";

const style = {

  position: "fixed",
  
  justifyContent: "center",
  top: '50%',
  maxWidth:'600px',
  backdrop: false,
  overlay:'red',
  left: '50%',
  maxHeight:'500px',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY:'auto'
};

export default function TeamCarousel({ hasBg }) {
  const [TeamMembers, setTeamMembers]=useState([])
  const [open, setOpen] = React.useState(false);
  const [description,setDescription] = useState()
  const [title,setTitle] = useState()
  const [image,setImage] = useState()
  const [name,setName] = useState()
  const handleOpen = (description,name,jobtitle,image) => {
    setDescription(description);
    setName(name);
    setTitle(jobtitle);
    setImage(image);
    setOpen(true)};
  const handleClose = () => setOpen(false);

  useEffect(()=>{
      const getAllEntries = async () =>{
          try { 
              await client.getEntries({
                  content_type: "team"
                }).then((entries) =>{
                  console.log(entries)
                  setTeamMembers(entries)
              })
          }  catch(error){
              console.log("error");
          }
      }
      getAllEntries()
  },[])
  
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 7000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
        <section id='team'
        className={`client-section ptb-100 ${hasBg ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                {hasBg ? (
                  <span className="text-uppercase color-secondary sub-title">
                   
                  </span>
                ) : (
                  ""
                )}
                <h2> Our Team</h2>
                <p className="lead">
                
                </p>
              </div>
            </div>
          </div>
     

        <Slider {...settings}>
       {TeamMembers.items?.map((post)=>    
      
      <div class="wrapper">
        
    <div class="profile" key={post.sys.id}>
      <img src={post.fields.image.fields.file.url} class="thumbnail"/>
      <div class="check"><i class="fas fa-check"></i></div>
      <h3 class="name">{post.fields.name} </h3>
      <p class="title">{post.fields.jobTitle}</p>
      
      <div class="social-menu">
          <ul>
           
              <li><a style={{cursor:'pointer'}} onClick={() => handleOpen(post.fields.description,post.fields.name,post.fields.jobTitle,post.fields.image.fields.file.url)} target="blank"> <FontAwesomeIcon icon={faInfo} style={{color:'#283653',position:'relative', top:'8px'}}/></a></li>
              <li><a href={post.fields.linkedin} target="blank"><FontAwesomeIcon icon={faLinkedinIn} style={{color:'#283653',position:'relative', top:'8px'}}/></a></li>
              <li><a href={post.fields.email}target="blank"><FontAwesomeIcon icon={faEnvelope} style={{color:'#283653',position:'relative', top:'8px'}}/></a></li>
            
          </ul>
      </div>
    </div>
  
    </div>
 )}
        </Slider>
  </div>
  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       
      >
        <Box sx={style}>
        <img src={image} style={{float:'left',width:'80px',borderRadius:'80px', marginRight:'20px',top:'-6px', position:'relative', border:'2px solid #6497da'}}/>
        
        <div id='nametitle'>
        <h5 className="name" style={{margin: '1rem 0 0rem',fontSize:'16px'}}>{name} </h5>
        <p className="title" style={{color:'#6497da',fontWeight:'400'}}>{title}</p>
        </div><img src={logo} id='logostyle'/>
          <Typography id="modal-modal-title" style={{textAlign:'justify'}}>
            {description}
          </Typography>
         
        </Box>
      </Modal>
    </section>
    </>
  );
}
