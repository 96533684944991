import React,{useContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'

const Whyus = () => {
  const {about, isAboutLoading} = useContext(Context)
  const {aboutTitle2, aboutDescription2, aboutTitle} = about
  return (
    <>
      <section className="promo-section ptb-100" id='whyus' style={{backgroundColor:'#f0f6fd'}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-md-10">
              <div className="section-heading text-center mb-5">
              <h2>{aboutTitle2} </h2>
              <p style={{textAlign:'justify'}}>
                <div dangerouslySetInnerHTML={{__html:aboutDescription2}}/>
                </p>
              </div>
            </div>
          </div>
         
        
        </div>
      </section>
    </>
  );
};

export default Whyus;
