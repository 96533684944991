import React from "react";
import { Routes, Route } from "react-router-dom";



import HomeTwo from "../themes/index/Home";
import About from "../pages/About"


import Verify from "../pages/Verify";
import SmartGo from "../pages/SmartGo";
import Use from "../pages/Use";

import Impressum from "../pages/Impressum";

import ScrollToTop from "./ScrollToTop";
import Intema from "../pages/Intema";
import Projects from "../pages/Projects";


const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeTwo/>} />
        <Route path="/impressum" element={<Impressum/>} />
        <Route path="/home" element={<HomeTwo/>} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/smartgo" element={<SmartGo />} />
        <Route path="/use" element={<Use />} />
        <Route path="/intema" element={<Intema />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
       
      </Routes>
    </>
  );
};

export default AppRoutes;
