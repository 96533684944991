import React,{useContext} from "react";
import { Context } from "../../../context/Context";

export default function Footer({ space }) {
  const {footer, isFooterLoading} = useContext(Context)
  const { description, details} = footer
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            backgroundImage: "linear-gradient( to right, #313e5b, #192847 )"
            
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/sustenergo2.png"
                    alt="footer logo"
                    width="170"
                    className="img-fluid mb-3"
                  />
                  <div id='left' dangerouslySetInnerHTML={{__html:description}}/>

                  <div className="social-list-wrap">
                  
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Instagram">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="/about#whoweare">About Us</a>
                        </li>
                      
                        <li className="mb-2">
                          <a href="/about#team">Our Team</a>
                        </li>
                        <li className="mb-2">
                          <a href="/impressum">Impressum</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                     <div dangerouslySetInnerHTML={{__html:details}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      ©  All rights reserved
                    </p>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
