import React,{useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { client } from "../../context/client";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../../context/Context'

const Features = () => {
  const {use, isUseLoading} = useContext(Context)
    const {title4, description4, image4} = use
  return (
    <>
      <div className="overflow-hidden">
        <section id="about" className="about-us ptb-100" style={{backgroundColor:'#f1f6fc'}}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>
                  {title4}
                  </h2>
                  <div className="single-feature mb-4">
                 <p> <div dangerouslySetInnerHTML={{__html:description4}}/></p>

                  </div>  </div>  
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src={image4}
                    style={{borderRadius:'20px'}}
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Features;
