import React,{useContext,useEffect} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../context/Context'
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/header/PageHeader";

import Intro from "../components/Use/Intro3";
import Features from "../components/Use/Features3";

import Whychoose from "../components/Use/Whychoose3";
import Whoisitfor from "../components/Use/Whoisitfor3";

export default function Use() {
  const {use, isUseLoading} = useContext(Context)
  const {logo, tagline} = use

  useEffect(() => {
    // Update the document title using the browser API
   console.log(5)
  });
  
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        image={logo}
        PageTitle={tagline}
        Parent="Blog"
      />
      <Intro/>
      <Whychoose/>
      <Whoisitfor/>
      <Features/>
      <Footer />
    </Layout>
  );
}
