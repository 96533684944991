import React,{useContext} from "react";
import {marked} from 'marked'
import DOMPurify from 'dompurify'
import { Context } from '../context/Context'
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/header/PageHeader";

import Whychoose2 from "../components/SmartGo/Whychoose2";
import Features2 from "../components/SmartGo/Features2";
import Intro2 from "../components/SmartGo/Intro2";
import Whoisitfor2 from "../components/SmartGo/Whoisitfor2";

export default function SmartGo() {
  const {Smartgo, isSmartgoLoading} = useContext(Context)
  const {logo, tagline} = Smartgo
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        image={logo}
        PageTitle={tagline}
        Parent="Blog"
      />
      <Whychoose2/>
      <Intro2/>
      <Whoisitfor2/>
      <Features2/>
      <Footer />
    </Layout>
  );
}
