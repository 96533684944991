import {marked} from 'marked'
import DOMPurify from 'dompurify'

export const getHTMLData = (rawdata) => {
    const htmlString = marked(rawdata)
    const santizedHTMLString = DOMPurify.sanitize(htmlString)
    return santizedHTMLString
}


export const CleanUpAbout = (rawData) => {

    const {sys, fields} = rawData
    const {id} = sys
    const aboutTitle = fields.title
    const aboutDescription = getHTMLData(fields.description)
    const aboutTitle2 = fields.title2
    const aboutDescription2 = getHTMLData(fields.description2)
    let cleanAbout ={ id,  aboutTitle, aboutDescription,aboutDescription2, aboutTitle2}

    return cleanAbout
}


export const CleanUpSolutions = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const smalltitle = fields.smallTittle
    const bigtitle = fields.bigTitle
    const solution1text1 = getHTMLData(fields.solution1Text1)
    const solution1text2 = getHTMLData(fields.solution1Text2)
    const solution2text1 = getHTMLData(fields.solution2Text1)
    const solution2text2 = getHTMLData(fields.solution2Text2)
    const solution3text1 = getHTMLData(fields.solution3Text1)
    const solution3text2 = getHTMLData(fields.solution3text2)
    const solution4text1 = getHTMLData(fields.solution4Text1)
    const solution4text2 = getHTMLData(fields.solution4Text2)
    const logo1 = fields.logo1.fields.file.url
    const logo2 = fields.logo2.fields.file.url
    const logo3 = fields.logo3.fields.file.url
    const logo4 = fields.logo4.fields.file.url
  
    
   
    let cleanSolutions = {id, smalltitle, bigtitle, solution1text1, solution1text2, solution2text1, solution2text2, solution3text1, solution3text2,solution4text1, solution4text2, logo1, logo2, logo3, logo4}

    return cleanSolutions
}

export const CleanUpHome = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const title = fields.title
    const description = getHTMLData(fields.description)
    const image = fields.image.fields.file.url
    let cleanHome = {id, title, description, image}

    return cleanHome
}

export const CleanUpContact = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const title= fields.title
    const description = getHTMLData(fields.description)
    let cleanContact = {id, title, description}

    return cleanContact
}

export const CleanUpFooter = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const description = getHTMLData(fields.description)
    const details = getHTMLData(fields.details)
    let cleanContact = {id, description, details}

    return cleanContact
}

export const CleanUpVerify = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const logo = fields.logo.fields.file.url
    const tagline = fields.tagline
    const title1 = fields.title1
    const description1 = getHTMLData(fields.description1)
    const title2 = fields.title2
    const description2 = getHTMLData(fields.description2)
    const image2 = fields.image2.fields.file.url
    const title3 = fields.title3
    const description3 = getHTMLData(fields.description3)
    const image3 = fields.image3.fields.file.url
    const title4 = fields.title4
    const description4 = getHTMLData(fields.description4)
    const image4 = fields.image4.fields.file.url
    let cleanVerify = {id, logo, tagline, title1, description1, title2, description2, title3, description3, title4, description4, image2, image3, image4}

    return cleanVerify
}

export const CleanUpSmartgo = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const logo = fields.logo.fields.file.url
    const tagline = fields.tagline
    const title1 = fields.title1
    const description1 = getHTMLData(fields.description1)
    const title2 = fields.title2
    const description2 = getHTMLData(fields.description2)
    const image2 = fields.image2.fields.file.url
    const title3 = fields.title3
    const description3 = getHTMLData(fields.description3)
    const image3 = fields.image3.fields.file.url
    const title4 = fields.title4
    const description4 = getHTMLData(fields.description4)
    const image4 = fields.image4.fields.file.url
    let cleanSmartgo = {id, logo, tagline, title1, description1, title2, description2, title3, description3, title4, description4, image2, image3, image4}

    return cleanSmartgo
}

export const CleanUpUse = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const logo = fields.logo.fields.file.url
    const tagline = fields.tagline
    const title1 = fields.title1
    const description1 = getHTMLData(fields.description1)
    const title2 = fields.title2
    const description2 = getHTMLData(fields.description2)
    const image2 = fields.image2.fields.file.url
    const title3 = fields.title3
    const description3 = getHTMLData(fields.description3)
    const image3 = fields.image3.fields.file.url
    const title4 = fields.title4
    const description4 = getHTMLData(fields.description4)
    const image4 = fields.image4.fields.file.url
    let cleanUse = {id, logo, tagline, title1, description1, title2, description2, title3, description3, title4, description4, image2, image3, image4}

    return cleanUse
}

export const CleanUpIntema = (rawdata) => {

    const{sys, fields} = rawdata
    const {id} = sys
    const logo = fields.logo.fields.file.url
    const tagline = fields.tagline
    const title1 = fields.title1
    const description1 = getHTMLData(fields.description1)
    const title2 = fields.title2
    const description2 = getHTMLData(fields.description2)
    const image2 = fields.image2.fields.file.url
    const title3 = fields.title3
    const description3 = getHTMLData(fields.description3)
    const image3 = fields.image3.fields.file.url
    const title4 = fields.title4
    const description4 = getHTMLData(fields.description4)
    const image4 = fields.image4.fields.file.url
    let cleanIntema = {id, logo, tagline, title1, description1, title2, description2, title3, description3, title4, description4, image2, image3, image4}

    return cleanIntema
}
